<!--  -->
<template>
  <div class="" style="height:100%">
    <vue-better-scroll
      class="wrapper"
      ref="scroll"
      :scrollbar="scrollbarObj"
      :pullDownRefresh="pullDownRefreshObj"
      :pullUpLoad="pullUpLoadObj"
      :startY="parseInt(startY)"
      @pulling-down="onPullingDown"
      @pulling-up="onPullingUp"
    >
      <div class="meetingList">
        <div
          class="singleMessageWrapper messageFlexBox"
          v-for="item in list"
          :key="item.messageId"
          @click="viewDetails(item)"
        >
          <div
            :class="[
              'singleMessageIcon',
              severityLevelColorChecktor(item.severityLevel)
            ]"
          >
            <span>{{
              item.severityLevel ? item.severityLevel + "级" : ""
            }}</span>
          </div>
          <div class="singleMessageContentWrapper">
            <div class="meetingTheme">
              <div class="meetingTitle" style="font-weight:600">
                <span>【</span>
                <span>{{ item.level | levelStatus }}</span>
                <span>】</span>
                {{ item.title }}
              </div>
            </div>
            <div class="roomName" style="margin: 5px 0">
              <span style="margin-right:8px">{{ item.theme }}</span>
              <span>{{ `( ${item.roomName} )` }}</span>
            </div>
            <div class="meetingTime">
              <div class="time">
                <span>{{ item.startTime.slice(0, 16) }}</span>
                <span>~</span>
                <span>{{ item.endTime.slice(0, 16) }}</span>
              </div>
              <div class="icon">
                <div :class="['round', colorChecktor(item.read)]"></div>
                <div :class="['level', textColorChecktor(item.read)]">
                  {{ item.read ? "已读" : "未读" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-better-scroll>
    <!-- <button class="go-top"
            @click="scrollTo">返回顶部</button> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getMessageList } from "@/api/messageList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      active: "1",
      scrollbarObj: {
        fade: true
      },
      pullDownRefreshObj: {
        threshold: 60,
        stop: 40
      },
      pullUpLoadObj: {
        threshold: 20,
        txt: {
          more: "加载更多",
          noMore: "没有更多数据了"
        }
      },
      startY: 0, // 纵轴方向初始化位置
      scrollToX: 0,
      scrollToY: 0,
      scrollToTime: 700,
      query: {
        pageSize: 10,
        currentPage: 1,
        queryParam: ""
      },
      list: [],
      isRequiring: false
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentRoleId() {
      return this.$store.state.user.roleId;
    },
    userAuthority() {
      return this.$store.state.user.authority
        ? JSON.parse(this.$store.state.user.authority)
        : [];
    },
    isAdmin() {
      return this.$store.state.user.IsAdmin;
    }
  },
  //监控data中的数据变化
  watch: {},
  filters: {
    meetingStatus(status) {
      if (status == null || status == undefined) return "";
      let statusObj = {
        0: "未派单",
        1: "待开始",
        2: "进行中",
        3: "已完成",
        4: "已取消"
      };
      return `【 ${statusObj[status]} 】`;
    },
    levelStatus(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "VIP会议",
        2: "普通会议",
        3: "重要会议",
        4: "会议支持"
      };
      return `${levelObj[level]}`;
    }
  },
  //方法集合
  methods: {
    severityLevelColorChecktor(level) {
      if (level == null || level == undefined) return "greyLevelColor";
      let levelObj = {
        1: "redLevelColor",
        2: "yellowLevelColor",
        3: "greenLevelColor"
      };
      return `${levelObj[level]}`;
    },
    viewDetails(item) {
      this.$store.commit("SetCurrentMessage", item);
      this.$store.commit("SetNavBarTitle", "信息详情");
      this.$router.push("/messageDetails");
    },
    textColorChecktor(level) {
      return level ? `hasReadTextColor` : `newTextColor`;
    },
    colorChecktor(level) {
      if (level == null || level == undefined) return "";
      return level ? `hasReadTextRound` : `newTextRound`;
    },
    onSearch() {
      console.log(this.query, "search");
      this.query.currentPage = 1;
      this.search();
    },
    search() {
      getMessageList(this.query).then(res => {
        if (res.code === 10000) {
          this.list = res.data.records;
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    getMoreMeetings() {
      getMessageList(this.query).then(res => {
        this.isRequiring = false;
        if (res.code === 10000) {
          this.list = this.list.concat(res.data.records);
          if (res.data.records.length < this.query.pageSize) {
            this.$refs.scroll.forceUpdate(false);
          } else {
            this.$refs.scroll.forceUpdate(true);
          }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    // 滚动到页面顶部
    scrollTo() {
      this.$refs.scroll.scrollTo(
        this.scrollToX,
        this.scrollToY,
        this.scrollToTime
      );
    },
    onPullingDown() {
      // 模拟下拉刷新
      console.log("下拉刷新");
      this.query.currentPage = 1;

      getMessageList(this.query).then(res => {
        if (res.code === 10000) {
          this.list = res.data.records;
          // if (this.list.length < 30) {
          //   this.$refs.scroll.forceUpdate(true);
          // } else {
          this.$refs.scroll.forceUpdate(true);
          // }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    onPullingUp() {
      // 模拟上拉 加载更多数据
      console.log("上拉加载");
      if (this.isRequiring) {
        return;
      }
      this.query.currentPage++;
      this.isRequiring = true;
      this.getMoreMeetings();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.search();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.searchComponent {
  background-color: #fff;
  margin: 10px;
  margin-top: 50px;
  border-radius: 5px;
}
.searchComponent .van-tabs__wrap {
  margin-bottom: 0.5rem;
}
.searchComponent .van-tabs__line {
  background-color: #2bb7b3;
}
.myStyledRadio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.myStyledRadio .van-radio__icon {
  display: none;
}
.myStyledRadio .van-radio__label {
  font-size: 0.4rem;
  display: inline-block;
  border: 1px solid black;
  border-radius: 3px;
  width: 3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 1.3rem;
  text-align: center;
}
.myStyledRadio .van-radio__icon--checked + .van-radio__label {
  color: #2bb7b3;
  background-color: #e9f8f7;
}
.searchInputComponent {
  margin: 10px;
}
.singleWrapper {
  border-radius: 5px;
  margin: 10px;
}
.meetingTheme {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.meetingTheme .meetingTitle {
  font-size: 0.7rem;
  width: 99%;
  text-align: left;
}
.meetingTheme .status {
  font-size: 0.7rem;
  color: #2bb7b3;
  margin-left: 0.6rem;
}
.roomName {
  font-size: 0.3rem;
  color: #8e8e8e;
  text-align: left;
}
.icon {
  display: flex;
  align-items: center;
}
.round {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
.level {
  font-size: 0.5rem;
  margin-left: 0.3rem;
}
.meetingTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meetingTime .time {
  font-size: 0.3rem;
  color: #8e8e8e;
}

.hasReadTextRound {
  background-color: #2bb7b3;
}
.newTextRound {
  background-color: #ed6c00;
}

.hasReadTextColor {
  color: #2bb7b3;
}
.newTextColor {
  color: #ed6c00;
}
.messageFlexBox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
}
.singleMessageContentWrapper {
  background-color: #fff;
  border-radius: 3px;
  width: calc(100% - 1.2rem);
  padding: 8px;
  box-sizing: border-box;
}
.singleMessageIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1rem;
  font-size: 0.7rem;
  border-radius: 3px;
}
.singleMessageIcon span {
  width: 0.8rem;
  font-size: 0.7rem;
  text-align: center;
  margin: 0 auto;
}

.redLevelColor {
  background-color: #dc1414;
  color: #fff;
}
.yellowLevelColor {
  background-color: #ed6c00;
  color: #fff;
}
.greenLevelColor {
  background-color: #2bb7b3;
  color: #fff;
}
.greyLevelColor {
  background-color: #8e8e8e;
  color: #8e8e8e;
}
.singleMessageWrapper {
  margin: 5px;
}
</style>
